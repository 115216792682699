<mat-tab-group  [dynamicHeight]="true" [selectedIndex]="1">
    <mat-tab label="Keller">
        <div class="dashboard-row">
            <app-display title="Sauna-Raum" [style.color]="'#BD6000'" [data]="get('Sauna-Raum', 'temperature')"
                [linkquality]="getCurrent('Sauna-Raum', 'linkquality')" [battery]="getCurrent('Sauna-Raum', 'battery')"
                unit="temperature" unitString="°C"></app-display>
            <app-display title="Sauna-Raum" [style.color]="'#0090BD'" [data]="get('Sauna-Raum', 'humidity')"
                [linkquality]="getCurrent('Sauna-Raum', 'linkquality')" [battery]="getCurrent('Sauna-Raum', 'battery')"
                unit="humidity" unitString="%"></app-display>
            <app-display title="Hobbyraum" [style.color]="'#BD6000'" [data]="get('Hobbyraum', 'temperature')"
                [linkquality]="getCurrent('Hobbyraum', 'linkquality')" [battery]="getCurrent('Hobbyraum', 'battery')"
                unit="temperature" unitString="°C"></app-display>
            <app-display title="Hobbyraum" [style.color]="'#0090BD'" [data]="get('Hobbyraum', 'humidity')"
                [linkquality]="getCurrent('Hobbyraum', 'linkquality')" [battery]="getCurrent('Hobbyraum', 'battery')"
                unit="humidity" unitString="%"></app-display>
        </div>
    </mat-tab>
    <mat-tab label="EG">
        <div class="dashboard-row">
            <app-display title="Wohnzimmer" [style.color]="'#BD6000'" [data]="get('Wohnzimmer', 'temperature')"
                [linkquality]="getCurrent('Wohnzimmer', 'linkquality')" [battery]="getCurrent('Wohnzimmer', 'battery')"
                unit="temperature" unitString="°C"></app-display>
            <app-display title="Wohnzimmer" [style.color]="'#0090BD'" [data]="get('Wohnzimmer', 'humidity')"
                [linkquality]="getCurrent('Wohnzimmer', 'linkquality')" [battery]="getCurrent('Wohnzimmer', 'battery')"
                unit="humidity" unitString="%"></app-display>
            <app-display title="Wohnzimmer" [style.color]="'#c93'" [data]="get('Wohnzimmer-Lux', 'illuminance')"
                [linkquality]="getCurrent('Wohnzimmer-Lux', 'linkquality')"
                [battery]="getCurrent('Wohnzimmer-Lux', 'battery')" unit="illuminance" unitString="lx">
            </app-display>
            <app-display title="Ofen" [style.color]="'#d33'" [data]="get('oven', 'temperature')" linkquality="0" showMinMax="false" battery="100"
                unit="temperature" unitString="°C">
            </app-display>
        </div>
        <div class="dashboard-row">
            <app-display title="Liam" [style.color]="'#BD6000'" [data]="get('Liam', 'temperature')"
                [linkquality]="getCurrent('Liam', 'linkquality')" [battery]="getCurrent('Liam', 'battery')"
                unit="temperature" unitString="°C"></app-display>
            <app-display title="Liam" [style.color]="'#0090BD'" [data]="get('Liam', 'humidity')"
                [linkquality]="getCurrent('Liam', 'linkquality')" [battery]="getCurrent('Liam', 'battery')"
                unit="humidity" unitString="%"></app-display>
            <app-display title="Gäste-WC" [style.color]="'#BD6000'" [data]="get('EG-Gästebad-Temp', 'temperature')"
                [linkquality]="getCurrent('Gäste-WC-Temp', 'linkquality')"
                unit="temperature" unitString="°C"></app-display>
            <app-display title="Gäste-WC" [style.color]="'#0090BD'" [data]="get('EG-Gästebad-Temp', 'humidity')"
                [linkquality]="getCurrent('Gäste-WC-Temp', 'linkquality')"
                unit="humidity" unitString="%"></app-display>
        </div>
    </mat-tab>
    <mat-tab label="1.OG">
        <div class="dashboard-row">
            <app-display title="Ronja" [style.color]="'#BD6000'" [data]="get('OG-Ronja-Temp', 'temperature')"
                [linkquality]="getCurrent('OG-Ronja-Temp', 'linkquality')" [battery]="getCurrent('OG-Ronja-Temp', 'battery')"
                unit="temperature" unitString="°C"></app-display>
            <app-display title="Ronja" [style.color]="'#0090BD'" [data]="get('OG-Ronja-Temp', 'humidity')"
                [linkquality]="getCurrent('OG-Ronja-Temp', 'linkquality')" [battery]="getCurrent('OG-Ronja-Temp', 'battery')"
                unit="humidity" unitString="%"></app-display>
            <app-display title="Schlafzimmer" [style.color]="'#BD6000'" [data]="get('OG-Schlafzimmer-Temp', 'temperature')"
                [linkquality]="getCurrent('OG-Schlafzimmer-Temp', 'linkquality')"
                [battery]="getCurrent('OG-Schlafzimmer-Temp', 'battery')" unit="temperature" unitString="°C"></app-display>
            <app-display title="Schlafzimmer" [style.color]="'#0090BD'" [data]="get('OG-Schlafzimmer-Temp', 'humidity')"
                [linkquality]="getCurrent('OG-Schlafzimmer-Temp', 'linkquality')"
                [battery]="getCurrent('OG-Schlafzimmer-Temp', 'battery')" unit="humidity" unitString="%"></app-display>
            <app-display title="Arbeitszimmer" [style.color]="'#BD6000'" [data]="get('OG-Arbeitszimmer-Temp', 'temperature')"
                [linkquality]="getCurrent('OG-Arbeitszimmer-Temp', 'linkquality')"
                [battery]="getCurrent('OG-Arbeitszimmer-Temp', 'battery')" unit="temperature" unitString="°C"></app-display>
            <app-display title="Arbeitszimmer" [style.color]="'#0090BD'" [data]="get('OG-Arbeitszimmer-Temp', 'humidity')"
                [linkquality]="getCurrent('OG-Arbeitszimmer-Temp', 'linkquality')"
                [battery]="getCurrent('OG-Arbeitszimmer-Temp', 'battery')" unit="humidity" unitString="%"></app-display>
            <app-display title="Gästezimmer" [style.color]="'#BD6000'" [data]="get('OG-Gästezimmer-Temp', 'temperature')"
                [linkquality]="getCurrent('OG-Gästezimmer-Temp', 'linkquality')"
                [battery]="getCurrent('OG-Gästezimmer-Temp', 'battery')" unit="temperature" unitString="°C"></app-display>
            <app-display title="Gästezimmer" [style.color]="'#0090BD'" [data]="get('OG-Gästezimmer-Temp', 'humidity')"
                [linkquality]="getCurrent('OG-Gästezimmer-Temp', 'linkquality')"
                [battery]="getCurrent('OG-Gästezimmer-Temp', 'battery')" unit="humidity" unitString="%"></app-display>
            <app-display title="Gästez. Fenster" [style.color]="'#999'" [data]="get('Gästezimmer-Fenster', 'value')" [showMinMax]="false"
            [linkquality]="getCurrent('Gästezimmer-Fenster', 'linkquality')" [valueMap]="{'1':'Offen','0':'Zu'}" [battery]="getCurrent('Gästezimmer-Fenster', 'battery')"
            unit="value" unitString=""></app-display>
        </div>
        <div class="dashboard-row">
          <app-display title="Bad" [style.color]="'#BD6000'" [data]="get('OG-Bad-Temp', 'temperature')"
              [linkquality]="getCurrent('OG-Bad-Temp', 'linkquality')"
              [battery]="getCurrent('OG-Bad-Temp', 'battery')" unit="temperature" unitString="°C"></app-display>
          <app-display title="Bad" [style.color]="'#0090BD'" [data]="get('OG-Bad-Temp', 'humidity')"
              [linkquality]="getCurrent('OG-Bad-Temp', 'linkquality')"
              [battery]="getCurrent('OG-Bad-Temp', 'battery')" unit="humidity" unitString="%"></app-display>
          <app-display title="Kinderbad" [style.color]="'#BD6000'" [data]="get('OG-Kinderbad-Temp', 'temperature')"
              [linkquality]="getCurrent('OG-Kinderbad-Temp', 'linkquality')"
              [battery]="getCurrent('OG-Kinderbad-Temp', 'battery')" unit="temperature" unitString="°C"></app-display>
          <app-display title="Kinderbad" [style.color]="'#0090BD'" [data]="get('OG-Kinderbad-Temp', 'humidity')"
              [linkquality]="getCurrent('OG-Kinderbad-Temp', 'linkquality')"
              [battery]="getCurrent('OG-Kinderbad-Temp', 'battery')" unit="humidity" unitString="%"></app-display>
          <app-display title="Kinderb. Fenster" [style.color]="'#999'" [data]="get('Bad-Fenster', 'value')" [showMinMax]="false"
          [linkquality]="getCurrent('Bad-Fenster', 'linkquality')" [valueMap]="{'1':'Offen','0':'Zu'}" [battery]="getCurrent('Bad-Fenster', 'battery')"
          unit="value" unitString=""></app-display>
      </div>
    </mat-tab>
    <mat-tab label="Garten">
        <div class="dashboard-row">
            <app-display title="Gewächshaus" [style.color]="'#BD6000'" [data]="get('Gewächshaus', 'temperature')"
                [linkquality]="getCurrent('Gewächshaus', 'linkquality')"
                [battery]="getCurrent('Gewächshaus', 'battery')" unit="temperature" unitString="°C"></app-display>
            <app-display title="Gewächshaus" [style.color]="'#0090BD'" [data]="get('Gewächshaus', 'humidity')"
                [linkquality]="getCurrent('Gewächshaus', 'linkquality')"
                [battery]="getCurrent('Gewächshaus', 'battery')" unit="humidity" unitString="%"></app-display>
            <app-display title="Wetterstation" [style.color]="'#BD6000'" [data]="get('Wetterstation', 'temperature')"
                [linkquality]="getCurrent('Wetterstation', 'linkquality')"
                [battery]="getCurrent('Wetterstation', 'battery')" unit="temperature" unitString="°C"></app-display>
            <app-display title="Wetterstation" [style.color]="'#0090BD'" [data]="get('Wetterstation', 'humidity')"
                [linkquality]="getCurrent('Wetterstation', 'linkquality')"
                [battery]="getCurrent('Wetterstation', 'battery')" unit="humidity" unitString="%"></app-display>
            <app-display title="Tank" [style.color]="'#5090FF'" [data]="get('tank', 'value')" unit="value"
                unitString="l"></app-display>
            <app-display title="Hühner-Tür" [style.color]="'#999'" [data]="get('Hühnerhaus-Tür', 'value')" [showMinMax]="false"
                [linkquality]="getCurrent('Hühnerhaus-Tür', 'linkquality')" [valueMap]="{'1':'Offen','0':'Zu'}" [battery]="getCurrent('Hühnerhaus-Tür', 'battery')"
                unit="value" unitString=""></app-display>
            <app-display title="Hühner-Bew." [style.color]="'#999'" [data]="get('Hühnerhaus-Bewegung', 'value')" [showMinMax]="false"
                [linkquality]="getCurrent('Hühnerhaus-Bewegung', 'linkquality')" [valueMap]="{'1':'Aktiv','0':'-'}" [battery]="getCurrent('Hühnerhaus-Bewegung', 'battery')"
                unit="value" unitString=""></app-display>
        </div>
    </mat-tab>
</mat-tab-group>


<router-outlet></router-outlet>
