import { Component } from '@angular/core';
import { IMqttMessage, MqttService } from 'ngx-mqtt';
import { Subscription } from 'rxjs';
import { Data } from './model/data';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'home';
  private subscription: Subscription;
  private subscriptionTank: Subscription;
  public roomDataMap: Map<string, any> = new Map<string, any[]>();

  constructor(private _mqttService: MqttService) {}

  ngOnInit(): void {

    let subscription: Subscription = this._mqttService.observe('today-zigbee2mqtt/#').subscribe((msg: IMqttMessage) => {
      try {
        let t = msg.topic.split('/')
        if(t.length == 2) {
          const roomName = t[1];
          let payload = msg.payload.toString();
          this.roomDataMap[roomName] = JSON.parse(payload);
          console.log(`roomName: ${roomName} topic: ${msg.topic}`,'data:', JSON.parse(payload))
          // for contact sensors remap contact true/false to 0/1
          this.roomDataMap[roomName].forEach(data => {
            if(typeof data.contact != 'undefined')
              data.value = data.contact ? 0 : 1;
          });
          // for contact sensors remap contact true/false to 0/1
          this.roomDataMap[roomName].forEach(data => {
            if(typeof data.occupancy != 'undefined')
              data.value = data.occupancy ? 1 : 0;
          });
        }

      }
      catch(e) {
        console.log('not processed', e, msg);
      }
    });

    let subscriptionTank: Subscription = this._mqttService.observe('today-tank/liter/#').subscribe((msg: IMqttMessage) => {
      try {
        this.roomDataMap['tank'] = JSON.parse(msg.payload.toString());
      }
      catch(e) {
        console.log('not processed', msg);
      }
    });

    window.setTimeout( () => {
      // unsubscribe after 20 seconds, to limit the network trafic, all current data is now provided by the other topic
      subscription.unsubscribe();
      this.subscription = this._mqttService.observe('zigbee2mqtt/#').subscribe((msg: IMqttMessage) => {
        try {
          const roomName = msg.topic.split('/')[1];
          if(roomName != 'bridge' && !msg.retain) {
            let data = JSON.parse(msg.payload.toString());
            // for contact sensors remap contact true/false to 1/0
            if(typeof data.contact != 'undefined')
              data.value = data.contact ? 0 : 1;
            // for occupancy sensors remap occupancy true/false to 1/0
            if(typeof data.occupancy != 'undefined')
              data.value = data.occupancy ? 1 : 0;
            data.time = new Date();
            if(!this.roomDataMap[roomName])
              this.roomDataMap[roomName] = []
            this.roomDataMap[roomName].push(data);
            // throw away data from yesterday
            this.roomDataMap[roomName] = this.roomDataMap[roomName].filter( d => new Date(d.time).getDay() == new Date().getDay() );
          }
        }
        catch(e) {
          console.log('not processed',e, msg);
        }
      });
      subscriptionTank.unsubscribe();
      this.subscriptionTank = this._mqttService.observe('tank/liter/#').subscribe((msg: IMqttMessage) => {
        try {
          if(!msg.retain) {
            let data = {value:JSON.parse(msg.payload.toString()), time: null}
            data.time = new Date();
            this.roomDataMap['tank'].push(data);
            // throw away data from yesterday
            this.roomDataMap['tank'] = this.roomDataMap['tank'].filter( d => new Date(d.time).getDay() == new Date().getDay() );
          }
        }
        catch(e) {
          console.log('not processed', msg);
        }
      });


    } , 20000);
  }

  getMin(room: string, unit: string) {
    return this.roomDataMap[room].reduce( (a, c) => typeof(a) == 'object'  || c['unit'] < a ? c['unit'] : a);
  }

  getMax(room: string, unit: string) {
    return this.roomDataMap[room].reduce( (a, c) => typeof(a) == 'object'  || c['unit'] > a ? c['unit'] : a);
  }

  getCurrent(room: string, unit: string) {
    try {
      return this.roomDataMap[room][this.roomDataMap[room].length-1][unit];
    }
    catch {

    }
    return 0;
  }

  get(room: string, unit: string):Data[] {
    try {
      if(!this.roomDataMap[room])
        return [];

      return this.roomDataMap[room].map( d => {
        var r = {time: d['time']};
        r[unit] = d[unit];
        return r;
      });
    }
    catch(e) {
      console.log('error in get room: ', room, e);
      return;
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
