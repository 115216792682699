import { Component, OnInit, Input } from '@angular/core';
import { Data } from '../model/data';

@Component({
  selector: 'app-display',
  templateUrl: './display.component.html',
  styleUrls: ['./display.component.scss']
})
export class DisplayComponent implements OnInit {

  @Input() data: Data[] = [];
  @Input() title: string;
  @Input() unitString: string;
  @Input() unit: string;
  @Input() linkquality: number;
  @Input() battery: number;
  @Input() showMinMax: boolean = true;
  @Input() valueMap: any;
  sparklinePath:string;
  sparklineFill:string;

  constructor() { }

  ngOnInit() {
  }

  getCurrent() {
    if(this.data.length == 0) return '-';

    this.sparklinePath = this.getPath();
    this.sparklineFill = this.getPathFill();
    if(this.valueMap)
      return this.valueMap[this.data.slice(-1)[0][this.unit]]
    return this.format(this.data.slice(-1)[0][this.unit], '-')
  }

  getMin() {
    if(this.data.length == 0) return '-';
    return this.format(this.data.reduce( (a, c) => typeof(a) == 'object'  || c[this.unit] < a ? c[this.unit] : a))
  }

  getMax() {
    if(this.data.length == 0) return '-';
    return this.format(this.data.reduce( (a, c) => typeof(a) == 'object'  || c[this.unit] > a ? c[this.unit] : a))
  }

  getX(d:Date):number {
    d = new Date(d);
    return (d.getHours()*60+d.getMinutes()) /(60*24)*100;
  }

  getY(v: number): number {
    const values = this.data.map(d => d[this.unit]).filter(Number.isFinite);
    const [min, max] = [Math.min(...values), Math.max(...values)];
    return 99 - (max > min ? ((v - min) / (max - min)) * 100 : 0);
  }

  getPath():string {
    var r = 'M';
    for( var i in this.data ) {
      let d = this.data[i];
      r += `${this.getX(d['time'])},${this.getY(d[this.unit])} `;
    }

    return r;
  }

  getPathFill():string {
    if(this.data.length == 0) return '';
    return this.getPath() +  `100,${this.getY(this.data[this.data.length-1][this.unit])} 100,100 0,100 0,${this.getY(this.data[0][this.unit])}`;
  }

  getBatteryHeight1() {
    return Math.min(this.battery*12/100, 10)
  }

  getBatteryHeight2() {
    return Math.max((this.battery-80)*12/100, 0);
  }
  getMinutesCircle() {
    return ( Math.max(12.6/60.0*this.getMinutesSinceCurrentValue(),0.2)) + ' 12.6'
  }

  getMinutePointerRotation() {
    return  'rotate(-' + this.getMinutesSinceCurrentValue() * 6 + ',8,8)';
  }

  getMinutesSinceCurrentValue():number {
    if(this.data.length == 0) return 1000;

    let d:Date = new Date(this.data.slice(-1)[0]['time'])
    return Math.round(( new Date().getTime() - d.getTime() ) /1000/60)
  }

  format(v, placeholder = '') {
    if(v == null) return placeholder;
    v = Math.round(v*10)/10;
    return v.toString();
  }

}
