<div class="box"  *ngIf="data !== undefined">
  <div class="title">{{title}}</div>
  <!-- link quality -->
  <svg *ngIf="linkquality" style="position:absolute;top: 23px;left: 130px;z-index:10" width="16" height="16">
    <title>{{linkquality}}</title>
    <g transform="scale(1 -1) translate(0,-16)">
      <rect x="0" y="0" width="2" height="3" [class.low-opacity]="linkquality < 5" fill="currentColor"></rect>
      <rect x="4" y="0" width="2" height="6" [class.low-opacity]="linkquality < 20" fill="currentColor"></rect>
      <rect x="8" y="0" width="2" height="9" [class.low-opacity]="linkquality < 40" fill="currentColor"></rect>
      <rect x="12" y="0" width="2" height="12" [class.low-opacity]="linkquality < 60" fill="currentColor"></rect>
    </g>
  </svg>
  <!-- battery-->
  <svg *ngIf="battery" style="position:absolute;top: 23px;left: 117px;z-index:10" width="16" height="16">
    <title>{{battery}}%</title>
    <g transform="scale(1 -1) translate(0,-16)">
      <path stroke-width="0" class="low-opacity" fill="currentColor" d="M0,0 0,10 2,10 2,12 5,12 5,10 7,10 7,0 0,0 "></path>
      <rect fill="currentColor" x=0 y=0 width=7 [attr.height]="getBatteryHeight1()"></rect>
      <rect fill="currentColor" x=2 y=10 width=3 [attr.height]="getBatteryHeight2()"></rect>
    </g>
  </svg>
  <!-- time-->
  <svg *ngIf="getMinutesSinceCurrentValue()<=60" style="position:absolute;top: 25px;left: 10px;z-index:10" width="16" height="16"  viewBox="-6 -6 12 12">
    <title>{{getMinutesSinceCurrentValue()}}</title>
    <g>
      <circle cx="0" cy="0" r="2" stroke="currentcolor" fill="none" stroke-width="4" transform="rotate(270)"  [attr.stroke-dasharray]="getMinutesCircle()" />
       <circle cx="0" cy="0" r="5" stroke="currentColor" stroke-width=".5" fill="transparent" />
      <!--<circle cx=8 cy=8 r=6 stroke-width=12 transform="rotate(270)" fill="none" stroke="currentColor" [attr.stroke-dasharray]="getMinutesCircle()" /> -->
      <!-- <line [attr.transform]="getMinutePointerRotation()" x1=8 y1=8 x2=8 y2=13 stroke-width="1" stroke="currentColor" /> -->
    </g>
  </svg>
  <!-- value -->
  <div class="temp-parent">
    <div class="temp">
      {{ getCurrent() }}
      <span class="unit">{{unitString}}</span>
    </div>
  </div>
  <div *ngIf="showMinMax" class="temp-min">
    <div class="min"></div>
    <div>{{ getMin() }}</div>
  </div>
  <div *ngIf="showMinMax" class="temp-max">
    <div class="max"></div>
    <div>{{ getMax() }}</div>
  </div>
    <svg class="sparkline" preserveAspectRatio="none" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
      <svg:g>
        <svg:path stroke="currentColor" stroke-width="1" fill="transparent" [attr.d]="sparklinePath"></svg:path>
      </svg:g>
      <svg:g style="opacity:0.2">
        <svg:path stroke="currentColor" stroke-width="1" fill="currentColor" [attr.d]="sparklineFill"></svg:path>
      </svg:g>
    </svg>
    <div style="opacity:0.2;background-color:currentColor;width:100%;height:5px;border-bottom-left-radius: 10px;border-bottom-right-radius: 10px;margin-top:-4px">
    </div>
    <svg preserveAspectRatio="none" style="margin-top:-4px" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
      <svg:path stroke="currentColor" stroke-width="1" fill="transparent" d="M4.17,2 4.17,3 M8.33,2 8.33,3 M12.5,2 12.5,3 M16.7,2 16.7,3 M20.8,2 20.8,3 M25.0,0 25.0,3 M29.2,2 29.2,3 M33.3,2 33.3,3 M37.5,2 37.5,3 M41.7,2 41.7,3 M45.8,2 45.8,3 M50.0,0 50.0,3 M54.2,2 54.2,3 M58.3,2 58.3,3 M62.5,2 62.5,3 M66.7,2 66.7,3 M70.8,2 70.8,3 M75.0,0 75.0,3 M79.2,2 79.2,3 M83.3,2 83.3,3 M87.5,2 87.5,3 M91.7,2 91.7,3 M95.8,2 95.8,3"></svg:path>
    </svg>
</div>